<template>
  <div
    class="flex flex-col justify-center  text-center w-full md:w-1/2 mx-auto pt-10 pb-24"
  >
  <img
        src="@/assets/muala-unofficial.svg" alt="logo Muala unofficial" class="w-48 mx-auto" 
      />    
      
      <div class="p-5">
      <h1 class="text-center font-bold text-3xl ctext-black">
        Dziękuję za Twoje wsparcie!
      </h1>
      <p class="pt-3 text-left">
        Wasze wsparcie jest dla mnie niezwykle ważne! Każda wirtualna kawa to nie tylko wsparcie finansowe, ale również ogromna motywacja do dalszej pracy nad rozwijaniem naszej mapy kulinarnych miejscówek. Dzięki Wam mogę utrzymac, aktualizować i dodawać nowe funkcje na muala.app.
       
      </p><p class="pt-3 text-left">

        Chcę wyróżnić każdego z Was, kto zdecydował się postawić mi wirtualną kawę. Poniżej znajdziesz listę ostatnich 15 osób, które wsparły mój projekt.
          Jeszcze raz serdecznie dziękuję za każdą kawę, za każde słowo wsparcia i za to, że jesteście ze mną!
      </p>
      </div>
      <div>
      <a href="https://buycoffee.to/mr.tomato" target="blank" class=" border-orange-500 font-bold transition duration-300 border-2 hover:border-orange-500 hover:text-orange-500 hover:bg-white  bg-orange-500 text-white rounded-full px-5 py-2 mx-2">
            dołączam do wsparcia
            </a>
          </div>

      <div class="text-left p-5 my-10 bg-gray-100 rounded-lg  ">

      <h2 class="text-center font-bold text-2xl text-black mt-5">
        Top 15 Ostatnich Wspierających      </h2>
      <br />

      <ul>

        

        <!-- placeholder -->
        <li v-if="!wspierajacySet" v-for="(n, index) in Array(5).fill().map((_, i) => i + 1)" :key="index" class="border-dashed border-2 p-5 pb-2 rounded-lg mb-3 bg-gray-200">
          <h3 class="font-bold text-xl">                
            <div class="h-4 bg-gray-400 rounded-full animate-pulse w-1/2"></div>
          </h3>
          <p>
            <div class="h-3 bg-gray-400 rounded-full col-span-1 mt-2 animate-pulse"></div>
            <div class="h-2 bg-gray-400 rounded-full col-span-1 mt-2 animate-pulse mb-3 w-1/4"></div>
          </p>
        </li>


      <li v-for="person in wspierajacySet" class="border-dashed border-2 p-5 pb-2 rounded-lg mb-3 bg-gray-200">
            <h3 class="font-bold text-xl">{{transformString(person.nickname)}}</h3>
            <p>
              {{person.msg}}
            </p>
            <span class="text-xs text-gray-400">{{formatFirebaseTimestamp(person.createdAt)}}</span>
      </li>


  </ul>
    </div>

    <div class="text-left mb-10">
    <h2 class="text-center font-bold text-2xl text-black mt-5">
      Chcesz dołączyć do grona wspierających?
    </h2>
      <p>
        Jeśli podoba Ci się to, co robię, i chcesz mnie wesprzeć, zapraszamy do postawienia wirtualnej kawy. Każda taka kawa to dla mnie sygnał, że moja praca jest dla Was ważna i wartościowa.
        <br><br>
        Dziękuję za każdy gest wsparcia!
        </p>
      </div>

    <div> 
      <a href="https://buycoffee.to/mr.tomato" target="blank" class="text-orange-500 font-bold transition duration-300 border-2 border-orange-500  hover:bg-orange-500 hover:text-white rounded-full px-5 py-2 mx-2">
        dołączam do wsparcia</a>
    </div>




    <div class="fixed inset-x-0 bottom-0 flex justify-center pb-4">
      <div
        @click="goToHomePage()"
        class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
      >
        <span class="material-icons-round">close</span>
      </div>
    </div>
  </div>
</template>

<router-link
  :to="{ name: 'opis' }"
  class="hover:text-orange-500 transition duration-300"
  @click="isMenu = false"
>
  o pomyśle
</router-link>

<script setup>
import { useRouter } from "vue-router";
import { ref, watchEffect, onBeforeMount  } from "vue";

import { db } from '../firebase/config';
import { collection, limit, orderBy, onSnapshot, query, where } from 'firebase/firestore';
import getCollection from '@/composables/getCollection';


const router = useRouter();

const goToHomePage = () => {
  router.push({ name: "map" });
};





function formatFirebaseTimestamp(firebaseTimestamp) {
    if (!firebaseTimestamp || typeof firebaseTimestamp !== 'object') {
      throw new Error("Invalid Firebase timestamp");
    }

    const seconds = firebaseTimestamp.seconds;
    const nanoseconds = firebaseTimestamp.nanoseconds;

    // Convert to milliseconds
    const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1e6);

    // Create a new Date object
    const date = new Date(milliseconds);

    // Extract day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1
    const year = date.getUTCFullYear();

    // Format day and month to always be two digits
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    // Return the formatted date
    return `${formattedDay}-${formattedMonth}-${year}`;
}



// function transformString(input) {
//     const words = input.split(' ');

//     const transformedWords = words.map((word, index) => {
//         if (index === 0) {
//             return word; 
//         }
//         return word[0] + '*'.repeat(word.length - 1); 
//     });

//     return transformedWords.join(' ');
// }

function transformString(input) {
    // Sprawdzamy czy input nie jest pusty lub undefined
    if (!input || typeof input !== 'string') {
        return '';
    }

    // Zamieniamy wielokrotne spacje na pojedyncze i usuwamy spacje z początku i końca
    const cleanInput = input.trim().replace(/\s+/g, ' ');
    
    // Dzielimy string na słowa
    const words = cleanInput.split(' ').filter(word => word.length > 0);

    const transformedWords = words.map((word, index) => {
        // Pierwsze słowo zostawiamy bez zmian
        if (index === 0) {
            return word;
        }
        
        // Dla słów jednoliterowych nie wykonujemy maskowania
        if (word.length <= 1) {
            return word;
        }
        
        // Dla pozostałych słów maskujemy wszystkie znaki oprócz pierwszego
        return word[0] + '*'.repeat(Math.max(0, word.length - 1));
    });

    return transformedWords.join(' ');
}

const { documents: wspierajacySet } = getCollection('wspierajacy', 15 );



</script>
