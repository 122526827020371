<template>
  <transition name="fade">
    <div v-if="!isMapReady" class="mapPlaceholder">
      <div class="loading-container">
        <div class="loader"></div>
      </div>
    </div>
  </transition>

  <GoogleMap
    ref="googleMapRef"
    api-key="AIzaSyCiNeI2j5GhrMCeyJcEtCmPxJdejQXr7r0"
    :center="props.center"
    :zoom="props.zoom"
    :options="{ mapTypeControl: false, backgroundColor: '#FF0000' }"
    mapId="29d876e44a2afeef"
    class="z-20 h-screen w-full"
    :libraries="['marker']"
  >
    <CustomMarker
      v-if="currentPosition"
      :options="{ position: currentPosition, anchorPoint: 'CENTER' }"
    >
      <div>
        <img :src="iconSrc" width="25" height="25" />
      </div>
    </CustomMarker>

    <MarkerCluster :options="clusterOptions">
      <AdvancedMarker
        v-for="(location, i) in locations"
        :options="{
          position: location,
          title: location.title,
          gmpClickable: true,
          zIndex: 1,
        }"
        :pin-options="location.isMuala ? {
          background: location.status === 'closed' ? '#d4d4d4' : '#EA4335',
          borderColor: location.status === 'closed' ? '#828282' : '#B31412',
          glyph: createCustomGlyph(location),
          scale: 1.3 
        } : null"


        @click="selectMarker(location.id)"
        :key="i"
      />
    </MarkerCluster>
  </GoogleMap>
</template>

<script setup>
import { ref, onMounted, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";

import {
  GoogleMap,
  AdvancedMarker,
  MarkerCluster,
  CustomMarker,
} from "vue3-google-map";
import clusterIcon from "@/assets/cluster-128.png";
import currentLocationIcon from "@/assets/current-position.png";

import { prepareUrlName } from "@/composables/prepareUrlName.js";

const router = useRouter();

const props = defineProps({
  selectedMarkerId: Number,
  selectedMarker: Object,
  markers: Array,
  center: Object,
  zoom: Number,
  isMapReady: Boolean,
});

const zoomNew = ref(props.zoom || 13);

const emit = defineEmits([
  "update:selectedMarkerId",
  "update:selectedMarker",
  "update:center",
  "update:isMapReady",
  "update:zoom",
]);

const iconSrc = ref(currentLocationIcon);
const currentPosition = ref(null);
const googleMapRef = ref(null);
const isMapReady = ref(props.isMapReady);

watch([() => googleMapRef.value?.ready], ([ready]) => {
  if (!ready) {
    return;
  } else {
    setTimeout(() => {
      isMapReady.value = true;
      emit("update:isMapReady", true);
    }, 1500);
  }
});

onMounted(() => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        currentPosition.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      },
      (error) => {
        console.error("Geolocation error:", error);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
});

// Dostosowanie struktury danych do starej wersji
const locations = ref(
  props.markers.map((item) => ({
    ...item.position, // Dodaje lat i lng do obiektu na pierwszym poziomie
    id: item.id,
    title: item.title,
    status: item.status,
    isMuala: item.isMuala,
    adres: item.adres, // Zakładam, że jest adres, jak w starej wersji
  }))
);

watchEffect(() => {
  locations.value = props.markers.map((item) => ({
    ...item.position, // Dodaje lat i lng do obiektu na pierwszym poziomie
    id: item.id,
    title: item.title,
    status: item.status,
    isMuala: item.isMuala,
    adres: item.adres, // Zakładam, że jest adres, jak w starej wersji
  }));
});

// Definicja niestandardowego renderera dla klastra
const customRenderer = {
  render({ count, position }) {
    const clusterDiv = document.createElement("div");
    clusterDiv.className = "cluster-marker";
    clusterDiv.innerHTML = `<span class="cluster-text">${count}</span>`;

    const icon = {
      url: clusterIcon,
      scaledSize: new google.maps.Size(64, 64),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(32, 32),
      labelOrigin: new google.maps.Point(32, 32),
    };

    const marker = new google.maps.Marker({
      position,
      icon,
      label: {
        text: String(count),
        color: "#FFFFFF",
        fontSize: "15px",
        fontWeight: "bold",
      },
    });

    return marker;
  },
};

const clusterOptions = {
  renderer: customRenderer,
  onClusterClick: (event, cluster, map) => {
    if (typeof cluster.getCenter === "function") {
      const center = cluster.getCenter();
      map.panTo(center);
    } else if (cluster._position) {
      const center = {
        lat: cluster._position.lat(),
        lng: cluster._position.lng(),
      };
      map.panTo(center);
    } else {
      console.log("Nie można znaleźć centrum klastra");
    }

    const newZoom = Math.min(map.getZoom() + 4, map.maxZoom || 20);
    map.setZoom(newZoom);
  },
};

function createCustomGlyph(location) {
  const div = document.createElement("div");

  // Wybór symbolu na podstawie statusu i isMuala
  let glyphSymbol;
  if (location.status === "closed") {
    glyphSymbol = "✖️";
  } else if (location.isMuala) {
    glyphSymbol = "😋";
  } else {
    glyphSymbol = "";
  }

  div.style.fontSize = location.isMuala ? "24px" : "16px"; 
  div.style.lineHeight = "1";
  div.style.textAlign = "center";
  div.textContent = glyphSymbol;

  return div;
}

// function customContent(location) {
// const div = document.createElement("div");
// div.innerHTML = `
//   <div style="padding: 5px; border-radius: 5px;">
//     <img src="${iconSrc.value}" alt="${location.title}" width="25" height="25" />
//   </div>
// `;
// return div;
// }

function goToLink(res) {
  const url = `/${prepareUrlName(res.adres.city)}/${prepareUrlName(
    res.title
  )}.html`;
  router.push(url);
}

const selectMarker = (id) => {
  const foundMarker = locations.value.find((marker) => marker.id === id);
  goToLink(foundMarker);
};
</script>

<style>
.custom-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.custom-marker-label {
  font-size: 18px;
  line-height: 1;
}

.gm-fullscreen-control,
.gm-style-mtc,
.gm-svpc {
  display: none;
}

.cluster-marker {
  position: absolute;
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 64px;
}

.cluster-text {
  display: block;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.mapPlaceholder {
  background-color: #bbe2c6;
  background-size: auto 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  z-index: 2;
  padding-bottom: 200px;
}

.loading-container {
  display: flex;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Loader animation */
.loader {
  width: 35px;
  height: 80px;
  position: relative;
}
.loader:before {
  content: "";
  position: absolute;
  inset: 0 0 20px;
  padding: 1px;
  background: conic-gradient(
      from -90deg at calc(100% - 3px) 3px,
      #7e3c26 135deg,
      #341612 0 270deg,
      #0000 0
    ),
    conic-gradient(
      from 0deg at 3px calc(100% - 3px),
      #0000 90deg,
      #341612 0 225deg,
      #7e3c26 0
    ),
    #54281f;
  background-size: 17px 17px;
  background-clip: content-box;
  --c: no-repeat linear-gradient(#000 0 0);
  -webkit-mask: var(--c) 0 0, var(--c) 17px 0, var(--c) 0 17px,
    var(--c) 17px 17px, var(--c) 0 34px, var(--c) 17px 34px,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: l6 3s infinite;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 60% 0 0;
  background: #b21b01;
  border-top: 5px solid #fff;
}

.advanced-marker {
  position: absolute;
  z-index: 9999;
}

@keyframes l6 {
  0%,
  14% {
    -webkit-mask-size: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  15%,
  29% {
    -webkit-mask-size: 17px 17px, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  30%,
  44% {
    -webkit-mask-size: 17px 17px, 17px 17px, 0 0, 0 0, 0 0, 0 0, auto;
  }
  45%,
  59% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0, 0 0, auto;
  }
  60%,
  74% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0,
      auto;
  }
  75%,
  89% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      0 0, auto;
  }
  90%,
  100% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      17px 17px, auto;
  }
}
</style>
